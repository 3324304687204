<template>
  <div>
    <div class="header">
      <img
        class="headerImage"
        :src="`/static/img/tool/toolBackground_${$i18n.locale}.jpg`"
      />
    </div>
    <div class="contents">
      <div class="download_btns">
        <a v-for="(item, index) in downloadList" :key="index" :href="item.href">
          <img class="downloadBtn" :src="item.image" alt="" />
        </a>
      </div>
      <div class="background"></div>
      <v-container>
        <div class="contentBackground hidden-sm-and-down">
          <div class="toolContent">
            <v-row>
              <v-col
                v-for="(con, y) in toolList"
                :key="y"
                class="article"
                cols="12"
                md="4"
                sm="4"
              >
                <div class="contents">
                  <div class="left">
                    <router-link :to="con.href" class="text-decoration-none">
                      <img :src="con.image" />
                      <span class="titleName">{{ translate(con.title) }}</span>
                    </router-link>
                  </div>
                  <div class="right"></div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="contentBackground hidden-md-and-up">
          <v-row class="toolContent" v-for="(item, i) in toolList" :key="i">
            <v-col class="article" cols="12">
              <div class="contents">
                <div class="left">
                  <router-link class="text-decoration-none" :to="item.href">
                    <img :src="item.image" />
                    <span class="titleName">{{ translate(item.title) }}</span>
                  </router-link>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { getNewList } from "@/api/news";
import {
  specialDetailPage,
  specialDownloadList,
} from "../../configs/special-content";
import newsList from "@/mixins/news/newsList";
export default {
  mixins: [newsList],
  data() {
    return {
      serverNewsList: [],
    };
  },
  computed: {
    toolList() {
      const localNewsList = specialDetailPage(this.$store.state.language).map(
        (item) => {
          return {
            ...item,
            href: `/special/${item.code}`,
          };
        }
      );
      return this.formatNews(this.serverNewsList, localNewsList, 6);
    },
    downloadList() {
      return specialDownloadList(this.$store.state.language);
    },
  },
  mounted() {
    this.getServerNewsist();
  },
  methods: {
    async getServerNewsist() {
      try {
        this.loading = true;
        const res = await getNewList({
          limit: 99,
          skip: 1,
          show_home: 0,
          token: this.$route.query.token || undefined,
          type: 6,
        });
        if (res.data.code !== 0) return;
        this.serverNewsList = res.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.download_btns {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  width: 1530px;
  margin: 52px auto 0;
  padding: 0 20px;
  a {
    display: block;
    &:nth-child(2) {
      margin: 0 88px;
    }
    img {
      width: 100%;
    }
  }
}
.background {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-image: url("/static/img/common/contentBackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
}

.header {
  position: relative;
  width: 100%;
  max-height: 500px;
  z-index: 3;

  .headerImage {
    @media only screen and (min-width: 2200px) {
      margin: 0 auto;
      max-width: 1920px;
    }
    display: block;
    width: 100%;
    height: auto;
  }
}

.container {
  max-width: 1200px;
}

.contentBackground {
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
}

.toolContent {
  margin-top: 0;
  position: relative;

  .article:nth-child(3n) {
    .contents {
      .right {
        background-image: none;
      }
    }
  }
  .article:last-child {
    .contents {
      .right {
        background-image: none;
      }
    }
  }

  .article {
    margin-top: 50px;
    padding: 0px;
    display: flex;
    align-items: center;
    height: 270px;
    background-color: #38357d;

    .contents {
      display: inline-flex;

      .left {
        margin: 0 0px 0 40px;
        float: left;
        text-align: center;
        a {
          display: block;
        }
        img {
          width: 100%;
          height: auto;
        }

        .titleName {
          color: #ffe84b;
          font-size: 16px;
          margin-top: 5px;
          margin-bottom: 0;

          &:hover {
            color: #ffffff;
          }
        }
      }

      .right {
        margin-left: 40px;
        float: right;
        width: 16px;
        background-image: url("/static/img/tool/divider.png");
        background-size: auto 80%;
        background-position-x: right;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .download_btns {
    width: 100%;
    padding: 0 20px;
    a {
      &:nth-child(2) {
        margin: 0 20px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .flexBox {
    justify-content: center;
    flex-wrap: wrap;
  }

  .inline-display {
    .item {
      width: 180px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .download_btns {
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 20px;
    a {
      margin-bottom: 20px !important;
      &:nth-child(2) {
        margin: 0;
      }
    }
  }
  .contentBackground {
    width: 450px;
    margin: 0 auto;

    .toolContent {
      .article {
        margin: 0;
        height: 300px;

        .contents {
          .left {
            margin: 0 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .flexBox {
    text-align: center;
    margin: 0 auto;
    flex-wrap: wrap;
  }

  .contentBackground {
    width: 380px;

    .toolContent {
      .article {
        .contents {
          .left {
            .titleName {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .header {
    .headerImage {
      min-height: 160px;
      width: 150%;
      margin-left: -70px;
    }

    .downloadBtn {
      width: 40%;
      left: 10%;
    }
  }

  .flexBox {
    text-align: center;
    justify-content: space-between;
  }

  .inline-display {
    .item {
      width: 150px;
      font-size: 20px;
    }
  }

  .contentBackground {
    width: 320px;

    .toolContent {
      .article {
        .contents {
          .left {
            .titleName {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
